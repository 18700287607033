import apiStoreUser from '../../../api/updateUser'
import { doSetAccount } from '../actions'
import { doDisplayMessage } from '../../message/actions'
import { Action, HandlerArgs } from '../../../types'
import { Account } from '..'

export interface Payload extends Account {
  redirect?: string
}

export default async function updateAccount(
  { dispatch, getState }: HandlerArgs,
  { payload }: Action<Payload>
) {
  const state = getState()
  const { accessToken } = state?.account || {}
  const { redirect } = payload

  const id = await apiStoreUser(payload, accessToken)

  if (id) {
    const message = 'Opplysningene dine ble oppdatert'
    dispatch(doDisplayMessage(message, false))
    dispatch(doSetAccount(payload, redirect))
  } else {
    const errorMessage =
      'Opplysningene dine kunne ikke oppdateres på grunn av en feil på serveren. Prøv igjen senere.'
    dispatch(doDisplayMessage(errorMessage, true))
    dispatch(doSetAccount({}))
  }

  return id
}
