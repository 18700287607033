import React from 'react'

export default function ContactWebmaster () {
  return (
    <p>
      Problemer med å bruke tjenesten? Send en e-post til{' '}
      <a href="mailto:webmaster@bergenber.no">webmaster@bergenber.no</a>.
    </p>
  )
}
