import React from 'react'
import './Button.css'

export interface Props {
  children?: React.ReactNode
  type?: "button" | "submit" | "reset"
  variant?: "button" | "primary" | "link" | "dropdown"
  disabled?: boolean
  inverse?: boolean
  noSpaceRight?: boolean
  dropped?: boolean
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>
  className?: string
}

export default function Button ({
  children,
  onClick,
  type = 'button',
  variant = "button",
  className: propsClassName,
  inverse,
  dropped,
  noSpaceRight,
  ...props
}: Props) {
  const className = ['Button', propsClassName]
  if (variant !== "button") {
    className.push(`Button-${variant}`)
  }
  if (props.disabled) {
    className.push('Button-working')
  }
  if (inverse) {
    className.push('Button-inverse')
  }
  if (noSpaceRight) {
    className.push('Button-noSpaceRight')
  }
  if (variant === "dropdown" && dropped) {
    className.push('Button-dropdownDropped')
  }
  const handler = onClick || (() => undefined)

  return (
    <button
      onClick={handler}
      type={type}
      className={className.filter(Boolean).join(' ')}
      {...props}
    >
      {children}
    </button>
  )
}
