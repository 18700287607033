import {
  CREATE_WATCH,
  DID_CREATE_WATCH,
  UPDATE_WATCH,
  DID_UPDATE_WATCH,
  FETCH_WATCH,
} from '../actions'
import createWatch from './createWatch'
import didCreateWatch from './didCreateWatch'
import updateWatch from './updateWatch'
import didUpdateWatch from './didUpdateWatch'
import fetchWatch from './fetchWatch'

export default [
  { action: CREATE_WATCH, afterHandler: createWatch },
  { action: DID_CREATE_WATCH, afterHandler: didCreateWatch },
  { action: UPDATE_WATCH, afterHandler: updateWatch },
  { action: DID_UPDATE_WATCH, afterHandler: didUpdateWatch },
  { action: FETCH_WATCH, afterHandler: fetchWatch },
]
