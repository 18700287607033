import { Action, HandlerArgs } from '../../../types'
import { Watch } from '..'
import apiUpdateWatch from '../../../api/updateWatch'
import { didUpdateWatch } from '../actions'
import { doDisplayMessage } from '../../message/actions'

export default async function updateWatch(
  { dispatch, getState }: HandlerArgs,
  action: Action<Watch>
) {
  const state = getState()
  const { accessToken } = state.account || {}

  const id = await apiUpdateWatch(action.payload, accessToken)

  if (id) {
    dispatch(didUpdateWatch(action.payload))
    return id
  } else {
    dispatch(
      doDisplayMessage(
        'Vakten kunne ikke oppdateres på grunn av en feil på serveren. Prøv igjen senere.',
        true
      )
    )
    return null
  }
}
