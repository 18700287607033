import React from 'react'
import Box from './Box'
import Link from './Link'
import dayNames from '../utils/dayNames'
import { WatchArray } from '../pages/RegisterWatch' // Not good

export interface Props {
  watch: WatchArray
  children?: React.ReactNode
}

export default function ConfirmDialog (props: Props) {
  const {watch, children} = props
  const [year, week, day, hour] = watch || []
  const dayName = (day) ? dayNames[day - 1].toLowerCase() : ''
  const hourString = ('0' + hour).substr(-2)
  const cancelUrl = `/bonnevakt/${year}/${('0' + week).substr(-2)}`

  return (
    <div>
      <Box variant="light">
        <p>
          <span role="img" aria-label="Tusen takk!">🙏</span> Du tar ansvar for <strong>{dayName} kl {hourString}:00</strong> – hver uke fra uke {week}, {year}.
        </p>

        {children}
      </Box>

      <p>
        <Link to={cancelUrl} variant="back">Angre og gå tilbake til kalenderen</Link>
      </p>
    </div>
  )
}
