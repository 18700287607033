import React from 'react';
import Content from './Content'
import Wrapper from './Wrapper'
import './Footer.css'

export default function Footer () {
  return (
    <footer className="Footer">
      <Wrapper>
        <Content id="footer" shouldSetMeta={false} />
      </Wrapper>
    </footer>
  )
}
