import fetch from 'isomorphic-fetch'
import watchesFromJson from './utils/watchesFromJson'
import { WeekPayload } from '../state/weeks'

export default async function fetchWeek(
  { year, week }: WeekPayload,
  accessToken: string | null = null
) {
  const url = `${process.env.REACT_APP_API_URL}/1.1/groups/1/watches/${year}/${week}`

  const headers = {
    'Content-Type': 'application/vnd.api+json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
  }

  try {
    const response = await fetch(url, { headers })
    if (response.ok) {
      return watchesFromJson(await response.json())
    }
  } catch (error) {}

  return null
}
