import { getISOWeeksInYear } from 'date-fns'

const maxISOWeek = (year: number) =>
  getISOWeeksInYear(new Date(`${year}-01-01`))

export function nextISOWeek(year: number, week: number) {
  if (week >= 52 && week === maxISOWeek(year)) {
    return { year: year + 1, week: 1 }
  } else {
    return { year, week: week + 1 }
  }
}

export function prevISOWeek(year: number, week: number) {
  if (week === 1) {
    return { year: year - 1, week: maxISOWeek(year - 1) }
  } else {
    return { year, week: week - 1 }
  }
}

export function formatISOWeek(year: number, week: number) {
  return `${year}-W${('0' + week).substr(-2)}`
}

export function parseISOWeek(isoWeek: string) {
  const year = parseInt(isoWeek.substr(0, 4))
  const week = parseInt(isoWeek.substr(6, 8))

  if (isNaN(year) || isNaN(week)) {
    throw new TypeError('Must be an ISO week string')
  }
  return { year, week }
}
