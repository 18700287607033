import decode from 'jwt-decode'
import { doSetAccount } from '../actions'
import apiFetchUser from '../../../api/fetchUser'
import { Action, HandlerArgs, JwtPayload } from '../../../types'

export default async function fetchAccount(
  { dispatch, getState }: HandlerArgs,
  { payload }: Action
) {
  const state = getState()
  const { accessToken } = state.account || {}
  if (!accessToken) {
    return null
  }

  let jwtPayload: JwtPayload
  try {
    jwtPayload = decode(accessToken)
  } catch (error) {
    return null
  }

  const account = await apiFetchUser({ id: jwtPayload.sub }, accessToken)

  if (!account) {
    return null
  }

  dispatch(doSetAccount(account))
  return account.id
}
