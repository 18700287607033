import React from 'react'
import {connect} from 'react-redux'
import {format as formatDate} from 'date-fns'
import {State} from '../../state'
import {Watch as WatchState} from '../../state/watches'
import './Watch.css'

export interface Props {
  date: Date
  hour?: number
  watch: WatchState
  accountId?: string | null
  onWatchClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>
  onEditClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>
}

export function Watch (props: Props) {
  const {date, hour, watch, accountId, onWatchClick, onEditClick} = props

  const watchTime = `${formatDate(date, 'dd.MM:yyyy')} kl ${('0' + hour).substr(-2)}:00`

  if (watch) {
    if (accountId === watch.userId) {
      return (
        <button
          title={`Endre vakten din ${watchTime}`} className="Watch Watch-own"
          onClick={onEditClick || (() => undefined)}>Din vakt</button>
      )
    } else {
      return (
        <span
          title={`Vakten ${watchTime} er tatt`}
          className="Watch Watch-taken">Tatt</span>
      )
    }
  } else {
    return (
      <button
        title={`Ta vakten ${watchTime}`} className="Watch Watch-free"
        onClick={onWatchClick}>Ta vakten</button>
    )
  }
}

const mapStateToProps = (state: State) => {
  const {id: accountId} = state.account || {}
  return {accountId}
}

export default connect(mapStateToProps)(Watch)
