import React from 'react'
import {connect} from 'react-redux'
import {doUpdateAccount} from '../state/account/actions'
import Link from '../components/Link'
import Content from '../components/Content'
import AccountForm from '../components/AccountForm'
import { State } from '../state'
import { Account } from '../state/account'

export interface Props {
  account?: Account
  year?: number
  week?: number
  updateAccount?: Function
}

export function WatchRegistered (props: Props) {
  const {account, updateAccount, year, week} = props
  const children = []
  const backUrl = `/bonnevakt/${year}/${('0' + week).substr(-2)}`

  if (account && !account.name) {
    children.push(<Content id="dine-opplysninger" isSubContent={true} shouldSetMeta={false} />)
    children.push(<AccountForm account={account} updateAccount={updateAccount} updating={account.updating} />)
  }

  return (
    <div>
      <Content id="vakten-er-registrert">
        {children}
      </Content>
      <p>
        <Link to={backUrl} className="c-link--back">Tilbake til kalenderen</Link>
      </p>
    </div>
  )
}

const mapStateToProps = (state: State) => {
  const {account} = state
  return {account}
}

const mapDispatchToProps = {
  updateAccount: doUpdateAccount
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchRegistered)
