import decode from 'jwt-decode'
import { getToken } from '../../../utils/auth0'
import { doAuthenticate } from '../actions'
import { Action, HandlerArgs } from '../../../types'

function isExpired(accessToken: string) {
  try {
    const { exp } = decode(accessToken)
    return exp ? new Date(exp * 1000) >= new Date() : false
  } catch (error) {
    return true
  }
}

export default async function fetchToken(
  { dispatch, getState }: HandlerArgs,
  { payload }: Action
) {
  const state = getState()
  const { accessToken: tokenFromState } = state.account || {}

  if (tokenFromState && !isExpired(tokenFromState)) {
    // Access token is already in state and is not expired, but as FETCH_TOKEN
    // was dispatched, we still have to authenticate agains the API.
    dispatch(doAuthenticate(tokenFromState))
  } else {
    // No access token in state -- fetch from auth service and authenticate
    // against API if set. This will never trigger a log in, the goal is simply
    // to fetch an already signed token.
    try {
      const tokenFromAuthService = await getToken()
      if (tokenFromAuthService) {
        dispatch(doAuthenticate(tokenFromAuthService))
      }
    } catch {
      // Swollow errors from auth service.
    }
  }
}
