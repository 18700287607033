import { Action } from '../../types'
import { Message } from '.'
import { DISPLAY_MESSAGE, HIDE_MESSAGE } from './actions'

export default function reducer(
  state: Message = { message: null, isError: false, display: false },
  { type, payload }: Partial<Action<Message>> = {}
) {
  if (payload) {
    switch (type) {
      case DISPLAY_MESSAGE:
        const { message, isError = false } = payload
        return { ...state, message, isError, display: true }
      case HIDE_MESSAGE:
        return { ...state, display: false }
    }
  }

  return state
}
