import React, {useState} from 'react'
import Box from './Box'
import Button from './Button'
import Field from './Field'
import Link from './Link'
import { Account } from '../state/account'

export interface Props {
  account?: Account
  updating?: boolean
  updateAccount?: Function
}

const fields = ['name', 'congregation', 'email', 'emailConsent']

export default function AccountForm (props: Props) {
  const {updateAccount, account, updating} = props
  const [inputs, setInputs] = useState(account)
  const formClass = (updating) ? 'c-working' : ''

  if (!account || !account.id) {
    return <p>Du er ikke logget inn.</p>
  }

  function submitHandler (event: React.MouseEvent<HTMLFormElement>) {
    event.preventDefault()
    if (typeof updateAccount === 'function') {
      updateAccount(inputs)
    }
  }

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = event.target as HTMLInputElement
    if (fields.includes(name)) {
      setInputs({ ...inputs, [name]: (type === 'checkbox') ? checked : value})
    }
  }

  return (
    <form onSubmit={submitHandler} method="post" className={formClass}>
        <fieldset>
          <p>
            <Field
              id="name"
              type="text"
              onChange={handleChange}
              value={inputs?.name || undefined}
              autoFocus
            >Navn</Field>
          </p>
          <p>
            <Field
              id="congregation"
              type="text"
              onChange={handleChange}
              value={inputs?.congregation}
            >Menighet / sammenheng</Field>
          </p>
          <p>
            <Field
              id="email"
              type="email"
              onChange={handleChange}
              value={inputs?.email}
            >E-post</Field>
          </p>
          <Box variant="light">
            <p>
              Fra tid til annen vil vi gjerne sende deg en e-post med inspirasjon
              til bønnearbeidet og oppdatering om hvordan det går med
              bønnevakttjenesten. Du kan melde deg av igjen når som helst.
            </p>
            <p>
              <Field
                id="emailConsent"
                type="checkbox"
                onChange={handleChange}
                value={inputs?.emailConsent}
                inverse
              >Jeg ønsker å få e-post fra Bergen ber</Field>
            </p>
          </Box>
          <Button type="submit" variant="primary">Lagre</Button>
          <Link to="/">Avbryt</Link>
        </fieldset>
    </form>
  )
}
