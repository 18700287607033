import React from 'react';
import Content from '../components/Content'
import './Page'

export interface Props {
  id?: string | null
}

export default function Page ({id}: Props) {
  return (
    <Content id={id} className="Page" />
  )
}
