import { Action, HandlerArgs } from '../../../types'
import { Watch } from '..'
import { didFetchWatch } from '../actions'
import { doDisplayMessage } from '../../message/actions'
import apiFetchWatch from '../../../api/fetchWatch'

export default async function fetchWatch(
  { dispatch, getState }: HandlerArgs,
  { payload }: Action<Watch>
) {
  const { id } = payload

  const state = getState()
  const { accessToken } = state.account || {}

  const watch = await apiFetchWatch({ id }, accessToken)

  if (watch) {
    dispatch(didFetchWatch(watch))
    return watch.id
  } else {
    const message =
      'Kunne ikke hente vakten din på grunn av en feil på serveren. Prøv igjen senere.'
    dispatch(doDisplayMessage(message, true))
    return null
  }
}
