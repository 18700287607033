import fetch from 'isomorphic-fetch'
import marked from 'marked'
import { Content } from '../state/content'

interface ApiContent extends Content {
  slug?: string
}

interface ApiData {
  items?: { fields: ApiContent }[]
}

const apiDataToState = (apiData: ApiData) => {
  if (!apiData.items || !apiData.items[0]) {
    return null
  }
  const {
    title,
    headline,
    intro,
    text,
    slug,
    metaDescription,
  } = apiData.items[0].fields

  return {
    id: slug,
    title,
    headline,
    intro: intro ? marked(intro) : undefined,
    text: marked(text || ''),
    metaDescription,
  }
}

export default async function fetchContent({ id }: Content) {
  const url = `${process.env.REACT_APP_CONTENT_URL}?content_type=article&fields.slug=${id}`
  const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_CONTENT_TOKEN}`,
  }

  const response = await fetch(url, { headers })

  if (response.ok) {
    const json = await response.json()
    return apiDataToState(json)
  }

  return null
}
