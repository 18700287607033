import fetch from 'isomorphic-fetch'
import { Account } from '../state/account'

export default async function apiAuthenticateUser(payload: Account) {
  const { accessToken } = payload
  const url = `${process.env.REACT_APP_API_URL}/1.1/auth`
  const body = {
    data: {
      type: 'user',
      attributes: {},
    },
  }

  let account = null
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  if (response.ok) {
    const { data } = await response.json()
    const { name, congregation, email, emailConsent } = data.attributes
    account = {
      id: data.id,
      name,
      congregation,
      email,
      emailConsent,
      accessToken,
    }
  }

  return account
}
