import { Account } from '.'
export const FETCH_TOKEN = 'FETCH_TOKEN'
export const AUTHENTICATE = 'AUTHENTICATE'
export const FETCH_ACCOUNT = 'FETCH_ACCOUNT'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const DO_SET_ACCOUNT = 'DO_SET_ACCOUNT'
export const SIGN_OUT = 'SIGN_OUT'
export const TOGGLE_ACCOUNT_MENU = 'TOGGLE_ACCOUNT_MENU'

export const doFetchToken = () => ({ type: FETCH_TOKEN, payload: {} })

export const doAuthenticate = (accessToken?: string) => ({
  type: AUTHENTICATE,
  payload: { accessToken },
})

export const doFetchAccount = () => ({ type: FETCH_ACCOUNT, payload: {} })

export const doUpdateAccount = (
  { id, name, congregation, email, emailConsent }: Account,
  redirect?: string
) => ({
  type: UPDATE_ACCOUNT,
  payload: { id, name, congregation, email, emailConsent, redirect },
})

export const doSetAccount = (
  { id, name, congregation, email, emailConsent, accessToken }: Account,
  redirect?: string
) => ({
  type: DO_SET_ACCOUNT,
  payload: {
    id,
    name,
    congregation,
    email,
    emailConsent,
    redirect,
    accessToken,
  },
})

export const doSignOut = () => ({ type: SIGN_OUT, payload: {} })

export const doToggleAccountMenu = (showMenu?: boolean) => ({
  type: TOGGLE_ACCOUNT_MENU,
  payload: { showMenu },
})
