import { Action, HandlerArgs } from '../../../types'
import { Watch } from '..'
import { doDisplayMessage } from '../../message/actions'
import { push } from 'redux-first-routing'
import { nextISOWeek, parseISOWeek } from '../../../utils/isoWeek'

export default async function didUpdateWatch(
  { dispatch }: HandlerArgs,
  { payload }: Action<Watch>
) {
  const { endWeek } = payload
  const nextWeek = parseISOWeek(endWeek as string)
  const { year, week } = nextISOWeek(nextWeek.year, nextWeek.week)

  dispatch(push(`/bonnevakt/${year}/${('0' + week).substr(-2)}`))
  dispatch(
    doDisplayMessage(
      `Vi har registrert at du ikke tar vakten fra og med uke ${week}, ${year}.`
    )
  )
}
