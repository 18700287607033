import { Action, HandlerArgs } from '../../../types'
import { Watch } from '..'
import { push } from 'redux-first-routing'

export default async function didCreateWatch(
  { dispatch }: HandlerArgs,
  { payload }: Action<Watch>
) {
  const { startWeek } = payload
  const year = startWeek?.substr(0, 4)
  const week = startWeek?.substr(6)

  dispatch(push(`/registrert?year=${year}&week=${week}`))
}
