import React from 'react'
import Link from '../Link'
import {prevISOWeek, nextISOWeek} from '../../utils/isoWeek'
import './Caption.css'

export interface Props {
  year?: number
  week?: number
}

const yearWeekLink = ({year, week}: Props) => `/bonnevakt/${year}/${('0' + week).substr(-2)}`

export default function Caption (props: Props) {
  const {week, year} = props
  if (week === undefined || year === undefined) {
    return null
  }
  return (
    <caption>
      <h2 className="Caption">Uke {week}, {year}</h2>
      <Link className="c-btn c-btn--link Caption-prevBtn" to={yearWeekLink(prevISOWeek(year, week))}>‹ Forrige uke</Link>
      <Link className="c-btn c-btn--link Caption-nextBtn" to={yearWeekLink(nextISOWeek(year, week))}>Neste uke ›</Link>
    </caption>
  )
}
