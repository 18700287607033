import fetch from 'isomorphic-fetch'
import { Watch } from '../state/watches'

export default async function updateWatch(
  payload: Watch,
  accessToken?: string | null
) {
  const { id, hour, day, startWeek, endWeek, userId } = payload

  if (!id) {
    return null
  }

  const url = `${process.env.REACT_APP_API_URL}/1.1/watches/${id}`
  const body = {
    data: {
      id,
      type: 'watch',
      attributes: { hour, day, startWeek, endWeek },
      relationships: {
        ...(userId && { user: { id: userId, type: 'user' } }),
      },
    },
  }
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${accessToken}`,
  }

  const response = await fetch(url, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(body),
  })

  if (response.ok) {
    return id
  }
  return null
}
