import React from 'react'
import Watch from './Watch'
import {addDays, addHours} from 'date-fns'
import dayIds from '../../utils/dayIds'
import getDayClass from '../../utils/getDayClass'
import {Watch as WatchState} from '../../state/watches'
import './calendar.css'

export interface Props {
  monday: Date
  hour?: number
  watches: WatchState[]
  onWatchClick?: Function
  onEditClick?: Function
}

export default function HourRow (props: Props) {
  const {monday, hour, watches, onWatchClick, onEditClick} = props
  const hourStr = ('0' + hour).substr(-2)
  const hourId = 'hour' + hourStr
  const hourText = hourStr
  
  const days = dayIds.map((dayId, index) => {
    const date = addDays(monday, index)
    const cellClass = 'cell ' + getDayClass(date)
    const watch = watches[index]

    return (
      <td key={index} headers={`col${dayId} ${hourId}`} className={cellClass}>
        <Watch date={addHours(date, hour || 0)} hour={hour} watch={watch}
          onWatchClick={() => onWatchClick && onWatchClick(index + 1)}
          onEditClick={() => onEditClick && onEditClick(watch.id)} />
      </td>
    )
  })

  return (
    <tr>
      <th key={-1} id={hourId} headers="colHours" className="cell cell-header">
        {hourText}<span className="hideOnMobile">:00</span>
      </th>
      {days}
    </tr>
  )
}
