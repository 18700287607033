import React from 'react'
import ConfirmDialog from './ConfirmDialog'
import Button from './Button'
import { WatchArray } from '../pages/RegisterWatch' // Not good

export interface Props {
  watch: WatchArray
  createWatch?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>
}

export default function ConfirmByClick (props: Props) {
  const {watch, createWatch} = props

  return (
    <section id="watchEdit">
      <h1>Neste steg: Bekreft vakten</h1>

      <p>Husk at du alltid kan logge deg inn senere og endre vakten din.</p>

      <ConfirmDialog watch={watch}>
        <Button onClick={createWatch} variant="primary" inverse>
          Bekreft vakten
        </Button>
      </ConfirmDialog>
    </section>
  )
}
