import fetch from 'isomorphic-fetch'
import { Watch } from '../state/watches'

export interface ApiData {
  id?: string
  type: string
  attributes: {
    hour?: number
    day?: number
    startWeek?: string
    endWeek?: string | null
    userId?: string
  }
  relationships: {
    user?: { id: string; type: string }
  }
}

const apiDataToState = (apiData: ApiData) => {
  const { id, attributes, relationships } = apiData
  const { hour, day, startWeek, endWeek } = attributes
  const { id: userId } = relationships.user || {}
  return { id, hour, day, startWeek, endWeek, userId }
}

export default async function fetchWatch(
  { id }: Watch,
  accessToken?: string | null
) {
  const url = `${process.env.REACT_APP_API_URL}/1.1/watches/${id}`

  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${accessToken}`,
  }

  const response = await fetch(url, { headers })
  if (response.ok) {
    const { data } = await response.json()
    return apiDataToState(data)
  }

  return null
}
