import React from 'react';
import {connect} from 'react-redux'
import {State} from './state/index'
import Home from './pages/Home'
import Page from './pages/Page'
import Signin from './pages/Signin'
import EditAccount from './pages/EditAccount'
import WatchRegistered from './pages/WatchRegistered'
import WatchCalendar from './pages/Calendar'
import RegisterWatch from './pages/RegisterWatch'
import EditWatch from './pages/EditWatch'

export interface Props {
  router?: {
    pathname?: string
    queries?: {
      [key: string]: string
    }
  }
}

// const WatchCalendar = (props, cb) =>
//   require.ensure([], require => cb(null, require('./pages/calendar').default), 'weeks')
// const RegisterWatch = (props, cb) =>
//   require.ensure([], require => cb(null, require('./pages/registerWatch').default), 'watchreg')
// const EditWatch = (props, cb) =>
//   require.ensure([], require => cb(null, require('./pages/editWatch').default), 'watchedit')

// <Route path="/bonnevakt/:year?/:week?" getComponent={WatchCalendar} />
// <Route path="/endre-vakt/:id" getComponent={EditWatch} />
// <Route path="/registrer" getComponent={RegisterWatch} />

const partString = (part: string) => (part === undefined) ? part : part.substr(1)
const partInt = (part: string) => (part === undefined) ? part : Number.parseInt(partString(part), 10)

export function Router (props: Props) {
  const {pathname, queries = {}} = props.router || {}

  const parts = pathname?.match(/\/[^/]*/g)

  if (!parts || parts.length === 0) {
    return <Page id={null} />
  }

  switch (parts[0]) {
    case '/':
      return <Home />
    case '/logginn':
      return <Signin />
    case '/konto':
      return <EditAccount />
    case '/registrert':
      return <WatchRegistered
        year={Number.parseInt(queries.year, 10)}
        week={Number.parseInt(queries.week, 10)} />
    case '/bonnevakt':
      return <WatchCalendar
        year={partInt(parts[1])}
        week={partInt(parts[2])} />
    case '/endre-vakt':
      return <EditWatch
        id={partString(parts[1])}
        year={parseInt(queries.year)}
        week={parseInt(queries.week)} />
    case '/registrer':
      return <RegisterWatch
        year={parseInt(queries.year)}
        week={parseInt(queries.week)}
        day={parseInt(queries.day)}
        hour={parseInt(queries.hour)} />
    default:
      return <Page id={partString(parts[0])} />
  }
}

const mapStateToProps = (state: State) => {
  const {router} = state
  return {router}
}

export default connect(mapStateToProps)(Router)
