import React from 'react';
import {connect} from 'react-redux'
import {push} from 'redux-first-routing'
import './Link.css'

export interface Props {
  children: React.ReactNode
  to: string
  dispathPush: Function
  variant?: string
  className?: string
}

export function Link (props: Props) {
  const {children, to, variant, dispathPush} = props
  const className = [
    variant ? `Link-${variant}` : undefined,
    props.className
  ].filter(Boolean).join(' ')

  const handleClick = (event: React.MouseEvent) => {
    // Ignore any click other than a left click
    if ((event.button && event.button !== 0) ||
      event.metaKey ||
      event.altKey ||
      event.ctrlKey ||
      event.shiftKey ||
      event.defaultPrevented === true) {
      return
    }

    // Prevent page reload
    event.preventDefault()

    // Push url
    dispathPush(to)
  }

  return <a href={to} onClick={handleClick} className={className}>{children}</a>
}

const mapDispatchToProps = {dispathPush: push}

export default connect(null, mapDispatchToProps)(Link)
