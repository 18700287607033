import {
  FETCH_TOKEN,
  AUTHENTICATE,
  FETCH_ACCOUNT,
  UPDATE_ACCOUNT,
  DO_SET_ACCOUNT,
  SIGN_OUT
} from '../actions'
import fetchToken from './fetchToken'
import authenticateUser from './authenticateUser'
import fetchAccount from './fetchAccount'
import updateAccount from './updateAccount'
import doSetAccount from './doSetAccount'
import doSignOut from './doSignOut'

export default [
  {action: FETCH_TOKEN, afterHandler: fetchToken},
  {action: AUTHENTICATE, afterHandler: authenticateUser},
  {action: FETCH_ACCOUNT, afterHandler: fetchAccount},
  {action: UPDATE_ACCOUNT, afterHandler: updateAccount},
  {action: DO_SET_ACCOUNT, afterHandler: doSetAccount},
  {action: SIGN_OUT, afterHandler: doSignOut}
]
