import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js'
import { doFetchToken } from '../state/account/actions'

export interface Dispatch {
  (action: { type: string; payload: unknown }): void
}

let auth0: Auth0Client | null = null

export async function init(dispatch: Dispatch) {
  auth0 = await createAuth0Client({
    domain: 'norgeber.eu.auth0.com',
    client_id: 'rLux71Wn2BBGrhY7j9ljN1oMJekctD7E',
    // redirect_uri: window.location.origin,
    audience: 'https://api-staging.norgeber.no',
    scope: 'openid profile email',
    response_type: 'token',
  })

  dispatch(doFetchToken())
}

export async function getToken() {
  return auth0 ? auth0.getTokenSilently() : null
}

export async function login(redirectPath = '') {
  if (auth0) {
    await auth0.loginWithRedirect({
      redirect_uri: `${window.location.origin}${redirectPath}`,
    })
  }
}

export async function logout() {
  if (auth0) {
    auth0.logout({
      returnTo: window.location.origin,
    })
  }
}
