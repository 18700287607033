import React from 'react'
import {connect} from 'react-redux'
import {doUpdateAccount} from '../state/account/actions'
import Link from '../components/Link'
import Content from '../components/Content'
import AccountForm from '../components/AccountForm'
import ContactWebmaster from '../components/ContactWebmaster'
import { State } from '../state'
import { Account } from '../state/account'

export interface Props {
  account?: Account
  updateAccount?: Function
}

export function EditAccount (props: Props) {
  const {account, updateAccount} = props

  const handleUpdate = updateAccount
    ? (account: Account) => { updateAccount(account, '/') } : undefined

  if (account?.id) {
    return (
      <div>
        <Content id="dine-opplysninger" />
        <AccountForm account={account} updateAccount={handleUpdate} updating={account.updating} />
        <ContactWebmaster />
      </div>
    )
  } else {
    return (<p>
      <Link to="/logginn">Logg inn</Link>{' '}
     for å endre dine opplysninger.
    </p>)
  }
}

const mapStateToProps = (state: State) => {
  const {account} = state
  return {account}
}

const mapDispatchToProps = {
  updateAccount: doUpdateAccount
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccount)
