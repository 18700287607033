import React from 'react'
import Content from '../components/Content'

export default function Home () {
  return (
    <section id="main">
      <Content id="index" />
    </section>
  )
}
