import { Action, HandlerArgs } from '../../types'
import { Content } from '.'
import { FETCH_CONTENT, didFetchContent } from './actions'
import apiFetchContent from '../../api/fetchContent'

const notFoundContent = (id?: string): Content => ({
  id,
  title: 'Siden finnes ikke',
  text:
    '<h1>Du har kommet til en side som ikke finnes</h1><p><a href="/" class="c-link--back">Gå til forsiden</a></p>',
  metaDescription: '',
})

export async function fetchContent(
  { dispatch }: HandlerArgs,
  { payload }: Action<Content>
) {
  const { id } = payload

  const content = await apiFetchContent({ id })

  if (content) {
    dispatch(didFetchContent(content))
    return content.id
  } else {
    dispatch(didFetchContent(notFoundContent(id)))
    return id
  }
}

export default { action: FETCH_CONTENT, afterHandler: fetchContent }
