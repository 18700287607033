import React from 'react'
import {connect} from 'react-redux'
import {getISOWeekYear, getISOWeek, getISODay, setISOWeekYear, setISOWeek, setISODay, setHours} from 'date-fns'
import {doCreateWatch} from '../state/watches/actions'
import ConfirmByAuth from '../components/ConfirmByAuth'
import ConfirmByClick from '../components/ConfirmByClick'
import ContactWebmaster from '../components/ContactWebmaster'
import {formatISOWeek, nextISOWeek} from '../utils/isoWeek'
import { State } from '../state'
import { Account } from '../state/account'

export interface WatchProps {
  year?: number
  week?: number
  day?: number
  hour?: number
}

export type WatchNumber = number | undefined
export type WatchArray = [WatchNumber, WatchNumber, WatchNumber, WatchNumber]

export interface Props extends WatchProps {
  account?: Account
  createWatch?: Function
}

const ensureFutureWatch = ({year, week, day, hour}: WatchProps = {}): WatchProps => {
  if (!year || !week || !day) {
    return {}
  }

  let date = new Date()
  date = setISODay(date, day)
  date = setISOWeek(date, week)
  date = setISOWeekYear(date, year)
  date = setHours(date, hour || 0)

  if (date >= new Date()) {
    return {year, week, day, hour}
  }

  const thisDay = getISODay(new Date())
  const thisWeek = getISOWeek(new Date())
  const thisYear = getISOWeekYear(new Date())

  if (thisDay < day) {
    return {year: thisYear, week: thisWeek, day, hour}
  } else {
    return {...nextISOWeek(thisYear, thisWeek), day, hour}
  }
}

export function RegisterWatch (props: Props) {
  const {createWatch, account} = props
  const {year, week, day, hour} = ensureFutureWatch(props)
if (year === undefined || week === undefined) {
  return null
}

  const startWeek = formatISOWeek(year, week)
  const watch = [year, week, day, hour] as WatchArray

  let confirm
  if (account && account.id) {
    confirm = <ConfirmByClick
      watch={watch}
      createWatch={() => createWatch && createWatch({hour, day, startWeek, userId: account.id})} />
  } else {
    confirm = <ConfirmByAuth watch={watch} />
  }

  return (
    <div>
      {confirm}
      <ContactWebmaster />
    </div>
  )
}

const mapStateToProps = (state: State) => {
  const {account} = state
  return {account}
}

const mapDispatchToProps = {
  createWatch: doCreateWatch
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterWatch)
