import React from 'react';
import Content from '../components/Content'
import SigninDialog from '../components/SigninDialog'
import ContactWebmaster from '../components/ContactWebmaster'

export default function Signin () {
  return (
    <div>
      <Content id="logg-inn" />
      <SigninDialog />
      <ContactWebmaster />
    </div>
  )
}
