import fetch from 'isomorphic-fetch'
import { Account } from '../state/account'

interface ApiData {
  id?: string
  type: string
  attributes: {
    name?: string
    congregation?: string
    email?: string
    emailConsent?: boolean
  }
  relationships: {}
}

function apiDataToState(apiData: ApiData) {
  const { id, attributes } = apiData
  const { name, congregation, email, emailConsent } = attributes
  return { id, name, congregation, email, emailConsent }
}

export default async function fetchUser({ id }: Account, accessToken?: string) {
  const url = `${process.env.REACT_APP_API_URL}/1.1/users/${id}`

  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${accessToken}`,
  }

  const response = await fetch(url, { headers })
  if (response.ok) {
    const { data } = await response.json()
    return apiDataToState(data)
  }

  return null
}
