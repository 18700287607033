import fetch from 'isomorphic-fetch'
import { Watch } from '../state/watches'

export default async function createWatch(
  payload: Watch,
  accessToken: string | null = null
) {
  const { hour, day, startWeek, endWeek = null, userId } = payload
  const url = `${process.env.REACT_APP_API_URL}/1.1/watches`
  const body = {
    data: {
      type: 'watch',
      attributes: { hour, day, startWeek, endWeek },
      relationships: {
        user: { id: userId, type: 'user' },
        group: { id: '1', type: 'group' },
      },
    },
  }
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${accessToken}`,
  }

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  })

  let id = null
  if (response.ok) {
    const json = await response.json()
    id = json.data.id
  }
  return id
}
