import React from 'react'
import Box from './Box'
import {login} from '../utils/auth0'
import Button from './Button'
import AuthInfo from './AuthInfo'

export interface Props {
  disabled?: boolean
  authenticateUser?: Function
}

export default function Signin (props: Props) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const dialog = isSafari ? (
    <p>
      Beklager, det er ikke mulig i logge inn fra Safari for øyeblikket.
      Bruk for eksempel <a href="https://www.google.com/chrome">Chrome</a> til
      vi får fikset problemet.
    </p>
  ) : (
    <p>
      <Button variant="primary" inverse {...props} onClick={() => login()}>
        Logg inn med mobilnummeret ditt
      </Button><br />
      <span>Du vil få en SMS med en engangskode fra Norge ber.</span>
    </p>
  )

  return (
    <div>
      <Box variant="light">
        {dialog}
      </Box>

      <AuthInfo />
    </div>
  )
}
