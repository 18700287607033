import { Action, HandlerArgs } from '../../../types'
import { Watch } from '..'
import apiCreateWatch from '../../../api/createWatch'
import { doDisplayMessage } from '../../message/actions'
import { didCreateWatch } from '../actions'

export default async function createWatch(
  { dispatch, getState }: HandlerArgs,
  action: Action<Watch>
) {
  const { hour, day, startWeek, endWeek, userId } = action.payload

  const state = getState()
  const { accessToken } = state.account || {}

  const id = await apiCreateWatch(action.payload, accessToken)

  if (!id) {
    const errorMessage =
      'Vakten kunne ikke registreres på grunn av en feil. Prøv igjen senere.'
    dispatch(doDisplayMessage(errorMessage, true))
  }

  dispatch(didCreateWatch({ id, hour, day, startWeek, endWeek, userId }))
  return id
}
