/* global localStorage */

import {createStore, applyMiddleware, compose} from 'redux'
import reducer from './reducer'
import {createBrowserHistory, routerMiddleware, startListener} from 'redux-first-routing'
import actionHandlers from './actionHandlers'

export interface ReduxWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
}

// Setup devtools extension
const devtoolsCompose = (typeof window !== 'undefined')
  ? (window as unknown as ReduxWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : null
const composeEnhancers = devtoolsCompose || compose

const storageKey = 'bergenberState'

const getStoredState = () => {
  try {
    const serializedState = localStorage.getItem(storageKey)
    if (serializedState) {
      return JSON.parse(serializedState)
    }
  } catch (err) {
  }
  return undefined
}

export default function setupStore () {
  // Setup history and router
  const history = createBrowserHistory()
  const middleware = routerMiddleware(history)

  const store = createStore(
    reducer,
    getStoredState(),
    composeEnhancers(
      applyMiddleware(middleware, actionHandlers())
    )
  )

  // Start router listener
  startListener(history, store)

  // if (module.hot) {
  //   // Enable webpack hot module replacement for reducers
  //   module.hot.accept(
  //     './reducer',
  //     () => {
  //       const nextReducer = require('./reducer').default
  //       store.replaceReducer(nextReducer)
  //     }
  //   )
  // }

  // Subscribe to changes and store access token in local storage
  store.subscribe(() => {
    const state = store.getState()
    const {accessToken} = state.account
    const storeState = {account: {accessToken}}

    try {
      const serializedState = JSON.stringify(storeState)
      localStorage.setItem(storageKey, serializedState)
    } catch (err) {
      // ignore
    }
  })

  return store
}
