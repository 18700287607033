import React from 'react'
import './Field.css'

interface PropsBase {
  id: string
  name?: string
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void
  children?: React.ReactNode
  inverse?: boolean
  autoFocus?: boolean
}

interface InputProps extends PropsBase {
  type?: "text" | "email"
  value?: string
}

interface CheckboxProps extends PropsBase {
  type?: "checkbox"
  value?: boolean
}

export type Props = InputProps | CheckboxProps

const isInputProps = (props: Props): props is InputProps => props.type !== 'checkbox'
const isCheckboxProps = (props: Props): props is CheckboxProps => props.type === 'checkbox'

const Input = ({ type = "text", id, name, value, inverse, onChange, autoFocus}: InputProps) =>
  <input
    type={type} id={id} name={name || id}
    value={value} onInput={onChange}
    className={`Field${inverse ? ' Field-inverse' : ''}`} autoFocus={autoFocus} />

const Checkbox = ({ id, name, inverse, value, onChange }: CheckboxProps) =>
  <input
    type="checkbox" id={id} name={name || id}
    defaultChecked={value} onChange={onChange}
    className={`Field Field-checkbox${inverse ? ' Field-inverse' : ''}`} />

export default function Field (props: Props) {
  const { children } = props
  return (
    <label>
      {isCheckboxProps(props) && <Checkbox {...props} />}
      {children}{isInputProps(props) ? ':' : ''}<br />
      {isInputProps(props) && <Input {...props} />}
    </label>
  )
}
