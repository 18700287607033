import React from 'react';
import Footer from './components/Footer'
import Header from './components/Header'
import Message from './components/Message'
import Wrapper from './components/Wrapper'
import './App.css';

export interface Props {
  children?: React.ReactNode
}

export default function App(props: Props) {
  return (
    <div>
      <Header />
      <Wrapper>
        <Message />
        {props.children}
      </Wrapper>
      <Footer />
    </div>
  );
}
