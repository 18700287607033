import { Watch } from '../watches'
export const FETCH_WEEK = 'FETCH_WEEK'
export const DID_FETCH_WEEK = 'DID_FETCH_WEEK'

export const doFetchWeek = (year: number, week: number) => ({
  type: FETCH_WEEK,
  payload: { year, week },
})

export const didFetchWeek = (
  year: number,
  week: number,
  watches: Watch[][] | null
) => ({
  type: DID_FETCH_WEEK,
  payload: { year, week, watches },
})
