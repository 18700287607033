import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import Box from '../components/Box'
import Button from '../components/Button'
import Link from '../components/Link'
import ContactWebmaster from '../components/ContactWebmaster'
import {doFetchWatch, doUpdateWatch} from '../state/watches/actions'
import dayNames from '../utils/dayNames'
import {formatISOWeek, prevISOWeek} from '../utils/isoWeek'
import { State } from '../state'

export interface Props {
  id?: string | null
  hour?: number
  day?: number
  week?: number
  year?: number
  startWeek?: string
  endWeek?: string | null
  userId?: string | null
  loading?: boolean
  accountId?: string | null
  fetchWatch?: Function
  updateWatch?: Function
}

export function EditWatch (props: Props) {
  const {
    id = null,
    hour,
    day,
    week,
    year,
    startWeek,
    endWeek,
    userId,
    loading,
    accountId,
    fetchWatch,
    updateWatch
  } = props

  useEffect(() => {
    if (id && startWeek === undefined && fetchWatch && !loading) {
      fetchWatch(id)
    }
  });

  if (year === undefined || week === undefined) {
    return null
  }
  const calendarUrl = `/bonnevakt/${year}/${('0' + week).substr(-2)}`

  const endWatch = () => {
    const prev = prevISOWeek(year, week)
    const newEndWeek = formatISOWeek(prev.year, prev.week)
    updateWatch && updateWatch({id, hour, day, startWeek, endWeek: newEndWeek, userId})
  }

  if (userId !== accountId) {
    return (
      <section id="editWatch">
        <h1>Dette er ikke din vakt</h1>
        <p>
          <Link to={calendarUrl} variant="back">Gå tilbake til kalenderen</Link>
        </p>
      </section>
    )
  } else if (loading || id === null) {
    return <section id="editWatch" className="c-working" />
  } else {
    const startWeekText = (startWeek) ? `${parseInt(startWeek.substr(6))}, ${startWeek.substr(0, 4)}` : ''
    const endWeekText = (endWeek) ? `${parseInt(endWeek.substr(6))}, ${endWeek.substr(0, 4)}` : ''
    const dayName = day ? dayNames[day - 1] : ''
    const endText = (endWeekText) ? `Varer til og med uke ${endWeekText}` : 'Varer til du sier fra deg ansvaret'

    return (
      <section id="editWatch">
        <Box variant={['light', 'bleed']}>
          <h1>Din bønnevakt: <strong>{dayName}er kl {('0' + hour).substr(-2)}:00</strong></h1>
          <ul>
            <li>Startet i uke {startWeekText}</li>
            <li>{endText}</li>
          </ul>
          <p>
            <Button onClick={endWatch} inverse>{/* bottomNone */}
              Avslutt denne vakten fra og med uke {week}, {year}
            </Button>
          </p>
          <p>
            <small>Avslutte i en annen uke? Gå <Link to={calendarUrl}>tilbake til kalenderen</Link> og klikk på vakten i uken du ønsker å avslutte i.</small>
          </p>
        </Box>
        <p>
          <Link to={calendarUrl} variant="back">Gå tilbake til kalenderen</Link>
        </p>
        <ContactWebmaster />
      </section>
    )
  }
}

const mapStateToProps = (state: State, ownProps: Props) => {
  const {id} = ownProps
  const {hour, day, startWeek, endWeek, userId, loading} = (state.watches && id && state.watches[id]) || {}
  const {id: accountId} = state.account || {}
  return {id, hour, day, startWeek, endWeek, userId, loading, accountId}
}

const mapDispatchToProps = {
  fetchWatch: doFetchWatch,
  updateWatch: doUpdateWatch
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWatch)
