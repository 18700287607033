import React from 'react';
import {connect} from 'react-redux'
import Box from './Box'
import {State} from '../state/index'
import './Message.css'

export interface Props {
  message?: string | null
  isError?: boolean
  display?: boolean
}

export function Message (props: Props) {
  const {message = null, isError = false, display = false} = props
  const messageClass = 'Message' + ((!display) ? ' Message-hidden' : '')

  if (message) {
    return (
      <section id="message" className={messageClass}>
        <Box variant={isError ? 'error' : 'message'}>{message}</Box>
      </section>
    )
  } else {
    return null
  }
}

export const mapStateToProps = (state: State = {}) => {
  const {message, isError, display} = state.message || {}
  return {message, isError, display}
}

export default connect(mapStateToProps)(Message)
