import { Action } from '../../../types'
import { Week } from '..'
import { FETCH_WEEK, DID_FETCH_WEEK } from '../actions'
import { DID_CREATE_WATCH, DID_UPDATE_WATCH } from '../../watches/actions'
import weekReducer from './week'
import { WeekPayload } from '..'

export default function reducer(
  state: Record<string, Week> = {},
  action: Partial<Action<WeekPayload>> = {}
) {
  const { type, payload } = action
  const { year, week } = payload || {}

  switch (type) {
    case FETCH_WEEK:
    case DID_FETCH_WEEK:
      const weekState = weekReducer(state[`${year}-${week}`], action)
      return { ...state, [`${year}-${week}`]: weekState }
    case DID_CREATE_WATCH:
    case DID_UPDATE_WATCH:
      return {}
  }

  return state
}
