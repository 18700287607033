import { Action, HandlerArgs } from '../../../types'
import { WeekPayload } from '..'
import apiFetchWeek from '../../../api/fetchWeek'
import { didFetchWeek } from '../actions'
import { doDisplayMessage } from '../../message/actions'

const errorMessage = 'Kunne ikke laste inn kalenderen. Prøv igjen senere.'

export default async function fetchWeek(
  { dispatch, getState }: HandlerArgs,
  action: Action<WeekPayload>
) {
  const { year, week } = action.payload
  if (year === undefined || week === undefined) {
    return dispatch(doDisplayMessage(errorMessage, true))
  }

  const state = getState()

  const watches = await apiFetchWeek({ year, week }, state.account?.accessToken)

  if (watches === null) {
    dispatch(doDisplayMessage(errorMessage, true))
  }
  dispatch(didFetchWeek(year, week, watches))
}
