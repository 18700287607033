import React from 'react';
import './Box.css'

export interface Props {
  variant?: string | string[]
  children?: React.ReactNode
  isParagraph?: boolean
}

export default function Box ({ children, isParagraph = false, variant = 'default' }: Props) {
  const className = [
    'Box',
    ([] as string[]).concat(variant).filter(Boolean).map(variant => `Box-${variant}`)
  ].filter(Boolean).join(' ')
  return isParagraph ? (
    <p className={className}>{children}</p>
  ) : (
    <div className={className}>{children}</div>
  )
}
