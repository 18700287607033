import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import {doFetchContent} from '../state/content/actions'
import { State } from '../state/index'
import './Content.css'

export interface Props {
  id?: string | null
  title?: string
  intro?: string
  headline?: string
  text?: string
  metaDescription?: string
  loading?: boolean
  className?: string
  children?: React.ReactNode
  isSubContent?: boolean
  shouldSetMeta?: boolean
  fetchContent?: Function
}

const setMeta = (title?: string, metaDescription?: string) => {
  document.title = (title) ? `${title} - Bergen ber` : 'Bergen ber'
  const meta = document.querySelector('meta[name="description"]')
  if (meta) {
    meta.setAttribute('content', metaDescription || '24/7 bønnevakt for Bergen')
  }
}

function loadContent (props: Props) {
  const {
    id,
    title,
    metaDescription,
    loading = false,
    shouldSetMeta = true,
    fetchContent
  } = props

  if (id && !loading && !title && typeof fetchContent === 'function') {
    console.log('Fetch content', id)
    fetchContent(id)
  } else if (shouldSetMeta) {
    setMeta(title, metaDescription)
  }
}

export function Content (props: Props) {
  const {
    intro,
    headline,
    text,
    loading,
    className = '',
    children,
    isSubContent = false
  } = props
  useEffect(() => {
    loadContent(props);
  });
    
  const content: React.ReactNode[] = []

  if (headline) {
    if (isSubContent) {
      content.push(<h2>{headline}</h2>)
    } else {
      content.push(<h1>{headline}</h1>)
    }
  }
  if (intro) {
    content.push(<div dangerouslySetInnerHTML={{__html: intro}} className="Content-intro"></div>)
  }
  if (Array.isArray(children)) {
    content.push(...children)
  } else if (children) {
    content.push(children)
  }
  if (text) {
    content.push(<div dangerouslySetInnerHTML={{__html: text}}></div>)
  }

  if (loading) {
    return (<div className="Content-working">&nbsp;</div>)
  } else {
    return (
      <article className={['Content', className].join(' ')}>
        {content}
      </article>
    )
  }
}

export const mapStateToProps = (state: State = {}, ownProps: Props = {}) => {
  const {id} = ownProps
  const {
    title,
    headline,
    intro,
    text,
    metaDescription,
    loading
  } = (state.content && id && state.content[id]) || {}

  return {id, intro, title, headline, text, metaDescription, loading}
}

export const mapDispatchToProps = {
  fetchContent: doFetchContent
}

export default connect(mapStateToProps, mapDispatchToProps)(Content)
