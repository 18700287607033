import { push } from 'redux-first-routing'
import { Action, HandlerArgs } from '../../../types'

export interface Payload {
  redirect?: string
}

export default async function doSetAccountHandler(
  { dispatch }: HandlerArgs,
  { payload }: Action<Payload>
) {
  const { redirect } = payload

  if (redirect) {
    dispatch(push(redirect))
  }
}
