import React from 'react'

export default function AuthInfo () {
  return (
    <p>
      <small>Innlogging skjer via tjenesten Auth0. Du identifiserer deg med
        mobilnummeret ditt, men Bergen ber lagrer bare en upersonlig id-kode.
        Mobilnummeret ditt lagres av Auth0, men de har ikke tilgang til noen
        av opplysningene du ellers registrerer hos Bergen ber-appen. Se{' '}
        <a href="https://auth0.com">nettsidene til Auth0</a> for mer
        informasjon om deres vilkår og retningslinjer.</small>
    </p>
  )
}
