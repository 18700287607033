import {combineReducers} from 'redux'
import weeks from './state/weeks/reducer'
import watches from './state/watches/reducer'
import account from './state/account/reducer'
import message from './state/message/reducer'
import content from './state/content/reducer'
import {routerReducer as router} from 'redux-first-routing'

export default combineReducers({
  weeks,
  watches,
  account,
  message,
  content,
  router
})
