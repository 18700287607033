import { Action } from '../../../types'
import { Week, WeekPayload } from '..'
import { FETCH_WEEK, DID_FETCH_WEEK } from '../actions'

export default function reducer(
  state: Week = { loading: false },
  action: Partial<Action<WeekPayload>> = {}
) {
  const { type, payload } = action

  if (payload) {
    switch (type) {
      case FETCH_WEEK:
        return { ...state, loading: true }
      case DID_FETCH_WEEK:
        return {
          ...state,
          loading: false,
          watches: payload.watches,
          loadedAt: Date.now(),
        }
    }
  }

  return state
}
