import React from 'react'
import Button from './Button'
import AuthInfo from './AuthInfo'
import ConfirmDialog from './ConfirmDialog'
import { WatchArray } from '../pages/RegisterWatch' // Not good
import {login} from '../utils/auth0'

export interface Props {
  watch: WatchArray
}

export default function ConfirmByAuth (props: Props) {
  const {watch} = props
  const [year, week, day, hour] = watch || []
  const confirmPath = `/registrer?year=${year}&week=${week}&day=${day}&hour=${hour}`
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const dialog = isSafari ? (
    <p>
      Beklager, det er ikke mulig i logge inn fra Safari for øyeblikket.
      Prøv for eksempel i <a href="https://www.google.com/chrome">Chrome</a> til
      vi får fikset problemet.
    </p>
  ) : (
    <>
      <Button variant="primary" inverse onClick={() => login(confirmPath)}>Bekreft med mobilnummer</Button>
      <p>Du vil få en SMS med en engangskode fra Norge ber.</p>
    </>
  )

  return (
    <section>
      <h1>Neste steg: Bekreft med mobilnummeret ditt</h1>

      <p>
        For å registrere vakten må du identifisere deg, men ingen opplysninger
        om deg vil bli synlig for andre på denne nettsiden. Du kan logge inn
        senere og gjøre endringer på vakten din med det samme mobilnummeret du
        oppgir nå.
      </p>

      <ConfirmDialog watch={watch}>
        {dialog}
      </ConfirmDialog>

      <AuthInfo />
    </section>
  )
}
