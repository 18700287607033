import wrapHandlers from 'redux-handler-middleware'
import watches from './state/watches/actionHandlers'
import weeks from './state/weeks/actionHandlers'
import account from './state/account/actionHandlers'
import message from './state/message/actionHandlers'
import content from './state/content/actionHandlers'
import router from './state/router/actionHandlers'

export interface ActionHandlerDefinition {
  action: string | string[],
  afterHandler: Function
}

export default () => wrapHandlers(([] as ActionHandlerDefinition[]).concat(
  watches,
  weeks,
  account,
  message,
  content,
  router
))
