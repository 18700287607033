import {isToday, isPast} from 'date-fns'

export default function getDayClass (date: Date) {
  if (isToday(date)) {
    return 'cell-today'
  }
  if (isPast(date)) {
    return 'cell-past'
  }
  return ''
}
