import React from 'react'
import {format as formatDate, addDays} from 'date-fns'
import dayIds from '../../utils/dayIds'
import getDayClass from '../../utils/getDayClass'
import dayNames from '../../utils/dayNames'
import './calendar.css'

export interface Props {
  monday?: Date
}

export default function DaysRow (props: Props) {
  const {monday} = props

  if (!monday) {
    return null
  }

  const days = dayNames.map((dayName, index) => {
    const date = addDays(monday, index)
    const cellClass = 'cell cell-header ' + getDayClass(date)
    return (
      <th key={index} id={`col${dayIds[index]}`} className={cellClass}>
        {dayName.substr(0, 3)}<span className="hideOnMobile">{dayName.substr(3)}</span>{' '}
        {formatDate(date, 'dd.')}<span className="hideOnMobile">{formatDate(date, 'MM')}</span>
      </th>
    )
  })

  return (
    <tr>
      <th id="colHours" className="cell cell-header cell-bottomAlign">Kl.</th>
      {days}
    </tr>
  )
}
