import { Content } from '.'

export const FETCH_CONTENT = 'FETCH_CONTENT'
export const DID_FETCH_CONTENT = 'DID_FETCH_CONTENT'

export const doFetchContent = (id?: string) => ({
  type: FETCH_CONTENT,
  payload: { id },
})

export const didFetchContent = ({
  id,
  title,
  headline,
  intro,
  text,
  metaDescription,
}: Content) => ({
  type: DID_FETCH_CONTENT,
  payload: { id, title, headline, intro, text, metaDescription },
})
