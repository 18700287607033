import { ApiData as ApiWatchData } from '../fetchWatch'
import { Watch } from '../../state/watches'

export interface ApiData {
  data?: ApiWatchData[]
}

export default function watchesFromJson(apiData?: ApiData) {
  const watches: Watch[][] = []
  for (let i = 0; i < 24; i++) {
    watches.push(Array(7).fill(null))
  }

  if (apiData && apiData.data) {
    apiData.data.forEach(({ id, attributes, relationships = {} }) => {
      const { hour, day } = attributes
      if (hour !== undefined && day !== undefined) {
        const { id: userId = null } = relationships.user || {}
        watches[hour][day - 1] = { id, userId }
      }
    })
  }

  return watches
}
