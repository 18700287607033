import { Watch } from '.'

export const FETCH_WATCH = 'FETCH_WATCH'
export const DID_FETCH_WATCH = 'DID_FETCH_WATCH'
export const CREATE_WATCH = 'CREATE_WATCH'
export const DID_CREATE_WATCH = 'DID_CREATE_WATCH'
export const UPDATE_WATCH = 'UPDATE_WATCH'
export const DID_UPDATE_WATCH = 'DID_UPDATE_WATCH'

export const doFetchWatch = (id: string) => ({
  type: FETCH_WATCH,
  payload: { id },
})

export const didFetchWatch = ({
  id,
  hour,
  day,
  startWeek,
  endWeek,
  userId,
}: Watch) => ({
  type: DID_FETCH_WATCH,
  payload: { id, hour, day, startWeek, endWeek, userId },
})

export const doCreateWatch = ({
  hour,
  day,
  startWeek,
  endWeek,
  userId,
}: Watch) => ({
  type: CREATE_WATCH,
  payload: { hour, day, startWeek, endWeek, userId },
})

export const didCreateWatch = ({
  id,
  hour,
  day,
  startWeek,
  endWeek,
  userId,
}: Watch) => ({
  type: DID_CREATE_WATCH,
  payload: { id, hour, day, startWeek, endWeek, userId },
})

export const doUpdateWatch = ({
  id,
  hour,
  day,
  startWeek,
  endWeek,
  userId,
}: Watch) => ({
  type: UPDATE_WATCH,
  payload: { id, hour, day, startWeek, endWeek, userId },
})

export const didUpdateWatch = ({
  id,
  hour,
  day,
  startWeek,
  endWeek,
  userId,
}: Watch) => ({
  type: DID_UPDATE_WATCH,
  payload: { id, hour, day, startWeek, endWeek, userId },
})
