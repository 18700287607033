import { Action } from '../../types'
import { Watch } from '.'
import {
  FETCH_WATCH,
  DID_FETCH_WATCH,
  DID_CREATE_WATCH,
  UPDATE_WATCH,
  DID_UPDATE_WATCH,
} from './actions'

const fields = ['hour', 'day', 'startWeek', 'endWeek', 'userId'] as const

const stateFromPayload = (payload: Watch) => {
  return fields.reduce(
    (state, key) =>
      payload[key] !== undefined ? { ...state, [key]: payload[key] } : state,
    {}
  )
}

function watchRecucer(state: Watch, { type, payload }: Partial<Action<Watch>>) {
  if (payload) {
    switch (type) {
      case FETCH_WATCH:
      case UPDATE_WATCH:
        return { ...state, loading: true }
      case DID_FETCH_WATCH:
      case DID_CREATE_WATCH:
      case DID_UPDATE_WATCH:
        return { ...state, ...stateFromPayload(payload), loading: false }
    }
  }
  return state
}

export default function reducer(
  state: Record<string, Watch> = {},
  action: Partial<Action<Watch>> = {}
) {
  const { type, payload } = action

  if (payload && payload.id) {
    switch (type) {
      case FETCH_WATCH:
      case DID_FETCH_WATCH:
      case DID_CREATE_WATCH:
      case UPDATE_WATCH:
      case DID_UPDATE_WATCH:
        const watchState = watchRecucer(state[payload.id], action)
        return { ...state, [payload.id]: watchState }
    }
  }

  return state
}
