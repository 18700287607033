import fetch from 'isomorphic-fetch'
import { Account } from '../state/account'

export default async function updateUser(
  payload: Account,
  accessToken?: string | null
) {
  const { id, name, congregation, email, emailConsent = false } = payload

  if (!id) {
    return null
  }

  const url = `${process.env.REACT_APP_API_URL}/1.1/users/${id}`
  const body = {
    data: {
      id,
      type: 'user',
      attributes: { name, congregation, email, emailConsent },
    },
  }
  const headers = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${accessToken}`,
  }

  const response = await fetch(url, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(body),
  })

  if (response.ok) {
    return id
  }
  return null
}
