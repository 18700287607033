import { Action, HandlerArgs } from '../../../types'
import { Account } from '..'
import apiAuthenticateUser from '../../../api/authenticateUser'
import { doSetAccount } from '../actions'
import { doDisplayMessage } from '../../message/actions'

export default async function authenticateUser(
  { dispatch }: HandlerArgs,
  { payload }: Action<Account>
) {
  const account = await apiAuthenticateUser(payload)

  if (account) {
    dispatch(doSetAccount(account))
    return account.id
  } else {
    dispatch(
      doDisplayMessage('Kunne ikke logge deg inn. Prøv igjen senere.', true)
    )
    return null
  }
}
