import { Action } from '../../types'
import { Content } from '.'
import { FETCH_CONTENT, DID_FETCH_CONTENT } from './actions'

const mapPayloadToState = ({
  title,
  headline,
  intro,
  text,
  metaDescription,
}: Content) => ({
  title,
  headline,
  intro,
  text,
  metaDescription,
  loading: false,
})

export default function reducer(
  state: Record<string, Content> = {},
  { type, payload }: Partial<Action<Content>> = {}
) {
  if (payload && payload.id) {
    switch (type) {
      case FETCH_CONTENT:
        return { ...state, [payload.id]: { loading: true } }
      case DID_FETCH_CONTENT:
        return { ...state, [payload.id]: mapPayloadToState(payload) }
    }
  }
  return state
}
