import React from 'react'; // , {useEffect}
import {connect} from 'react-redux'
import Button from './Button'
import Link from './Link'
import { State } from '../state/index'
import './Menu.css'
import './AccountMenu.css'
import {doSignOut, doToggleAccountMenu} from '../state/account/actions'

export interface Props {
  id?: string | null
  name?: string | null
  signOut?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>
  showMenu?: boolean
  toggleMenu?: Function
}

export function AccountMenu (props: Props) {
  const {id, name, signOut, showMenu = false, toggleMenu} = props
  const menuClass = 'AccountMenu AccountMenu-list' +
    ((showMenu) ? ' AccountMenu-visible' : '')

  // useEffect(() => {
  //   if (!id || !name) {
  //     const {dispatch} = this.context.store
  //     // dispatch(doFetchAccount())
  //   }
  // });

  const dropdownHandler = () => {
    toggleMenu && toggleMenu(!props.showMenu)
  }

  if (id) {
    // return <Button onClick={signOut}>Logg ut</Button>
    return (
      <div onClick={dropdownHandler}>
        <Button variant="dropdown" noSpaceRight dropped={showMenu} className="Menu-link">{name || 'Anonym'}</Button>
        <ul className={menuClass}>
          <li className="AccountMenu-item">
            <Link to="/konto" className="AccountMenu-link">Dine opplysninger</Link>
          </li>
          <li className="AccountMenu-item">
            <Button variant="link" className="AccountMenu-link" onClick={signOut}>Logg ut</Button>
          </li>
        </ul>
      </div>
    )
  } else {
    return (
      <Link to="/logginn" className="Menu-link">Logg inn</Link>
    )
  }
}

export const mapStateToProps = (state: State) => {
  const {id, name, showMenu} = state.account || {}
  return {id, name, showMenu}
}

export const mapDispatchToProps = {
  signOut: doSignOut,
  toggleMenu: doToggleAccountMenu
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu)
