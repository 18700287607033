import React from 'react';
import Ident from './Ident'
import Menu from './Menu'
import Wrapper from './Wrapper'

export default function Header () {
  return (
    <header>
      <Menu />
      <Wrapper>
        <Ident />
      </Wrapper>
    </header>
  )
}
