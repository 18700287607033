import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import {parseISO as parseDate} from 'date-fns'
import {doFetchWeek} from '../../state/weeks/actions'
import Caption from './Caption'
import DaysRow from './DaysRow'
import HourRow from './HourRow'
import { State } from '../../state'
import { Watch } from '../../state/watches'
import './calendar.css'

export interface Props {
  year?: number
  week?: number
  watches?: Watch[][]
  loading?: boolean
  loadedAt?: number | null
  registerWatch?: Function
  editWatch?: Function
  fetchWeek?: Function
}

function Week (props: Props) {
  const {year, week, watches, loading, loadedAt, registerWatch, editWatch, fetchWeek} = props
  const monday = parseDate(`${year}-W${('0' + week).substr(-2)}T00:00+01`)
  const tableClass = 'calendar' + ((loading) ? ' calendar-working' : '')

  useEffect(() => {
    // TODO: Trigger on week links instead
    if (!loading && (!loadedAt || loadedAt < Date.now() - 60000)) {
      fetchWeek && fetchWeek(year, week)
    }
  });

  const hours = watches?.map((watch, hour) =>
    <HourRow key={hour} monday={monday} hour={hour} watches={watch}
      onWatchClick={(day: number) => registerWatch && registerWatch(year, week, day, hour)}
      onEditClick={(id: string) => editWatch && editWatch(year, week, id)} />)

  return (
    <table id="calendar" className={tableClass}>
      <Caption year={year} week={week} />
      <thead>
        <DaysRow monday={monday} />
      </thead>
      <tbody>
        {hours}
      </tbody>
    </table>
  )
}

export const mapStateToProps = (state: State = {}, ownProps: Props = {}) => {
  const {year, week} = ownProps
  const weekState = (state.weeks) ? state.weeks[`${year}-${week}`] || {} : {}

  const {
    watches,
    loading = false,
    loadedAt = null
  } = weekState

  return {watches: watches || Array(24).fill([]), loading, loadedAt}
}

export const mapDispatchToProps = {
  fetchWeek: doFetchWeek
}

export default connect(mapStateToProps, mapDispatchToProps)(Week)
