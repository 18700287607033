import { Action, HandlerArgs } from '../../types'
import { Message } from '.'
import { DISPLAY_MESSAGE, doHideMessage } from './actions'

let timeout: number | null = null

function displayMessage(
  { dispatch }: HandlerArgs,
  { payload }: Action<Message>
) {
  if (payload.message !== null) {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = window.setTimeout(() => {
      dispatch(doHideMessage())
    }, 5000)
  }
}

export default { action: DISPLAY_MESSAGE, afterHandler: displayMessage }
