import { Account } from '.'
import { Action } from '../../types'
import {
  AUTHENTICATE,
  UPDATE_ACCOUNT,
  DO_SET_ACCOUNT,
  SIGN_OUT,
  TOGGLE_ACCOUNT_MENU,
} from './actions'

const fields = [
  'id',
  'name',
  'congregation',
  'email',
  'accessToken',
  'emailConsent',
] as const

const accountToState = (state: Account, payload: Account) =>
  fields.reduce(
    (nextState, key) =>
      payload[key] === undefined
        ? nextState
        : {
            ...nextState,
            [key]: payload[key],
          },
    state
  )

const emptyState = () => ({
  id: null,
  accessToken: null,
  updating: false,
})

export default function reducer(
  state: Account = emptyState(),
  { type, payload }: Partial<Action<Account>> = {}
) {
  if (payload) {
    switch (type) {
      case UPDATE_ACCOUNT:
        return { ...accountToState(state, payload), updating: true }
      case DO_SET_ACCOUNT:
        return { ...accountToState(state, payload), updating: false }
      case AUTHENTICATE:
        return { ...emptyState(), updating: true }
      case SIGN_OUT:
        return { ...emptyState(), updating: false }
      case TOGGLE_ACCOUNT_MENU:
        return { ...state, showMenu: payload.showMenu }
    }
  }

  return state
}
