import React from 'react';
import Link from './Link'
import AccountMenu from './AccountMenu'
import './Menu.css'

export default function Menu () {
  return (
    <nav className="Menu">
      <ul className="Menu-list">
        <li className="Menu-item">
          <Link to="/" className="Menu-link">Forsiden</Link>
        </li>
        <li className="Menu-item">
          <Link to="/bonnevakt" className="Menu-link">Bønnevakt</Link>
        </li>
        <li className="Menu-item">
          <AccountMenu />
        </li>
      </ul>
    </nav>
  )
}
