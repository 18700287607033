import React from 'react'
import {getISOWeekYear, getISOWeek} from 'date-fns'
import Weeks from '../components/Weeks'
import Content from '../components/Content'
import ContactWebmaster from '../components/ContactWebmaster'

// import '../style/calendar.scss'

export interface Props {
  year?: number
  week?: number
}

export default function Calendar (props: Props) {
  const {
    year = getISOWeekYear(new Date()),
    week = getISOWeek(new Date())
  } = props

  return (
    <div>
      <Content id="bonnevakt" />
      <Weeks year={year} week={week} />
      <ContactWebmaster />
    </div>
  )
}
