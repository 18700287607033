import { Action, HandlerArgs } from '../../types'

import { LOCATION_CHANGE } from 'redux-first-routing'

declare var gtag: (
  arg1: string,
  arg2: string,
  arg3: { page_path: string }
) => void

export interface Payload {
  pathname?: string
  search?: string
}

export function didLocationChange(
  { dispatch }: HandlerArgs,
  { payload }: Action<Payload>
) {
  const { pathname, search } = payload

  window.scrollTo(0, 0)

  if (typeof gtag === 'function') {
    gtag('config', 'GA_TRACKING_ID', { page_path: `${pathname}${search}` })
  }
}

export default { action: LOCATION_CHANGE, afterHandler: didLocationChange }
