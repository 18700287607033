import React from 'react'
import {connect} from 'react-redux'
import {push} from 'redux-first-routing'
import Week from './Week'

export interface Props {
  year?: number
  week?: number
  dispatchPush?: Function
}

function Calendar (props: Props) {
  const {year, week, dispatchPush} = props

  const gotoRegister = (year: number, week: number, day: number, hour: number) => {
    const url = `/registrer?year=${year}&week=${week}&day=${day}&hour=${hour}`
    if (typeof dispatchPush === 'function') {
      dispatchPush(url)
    }
  }

  const gotoEdit = (year: number, week: number, id: string) => {
    const url = `/endre-vakt/${id}?year=${year}&week=${week}`
    if (typeof dispatchPush === 'function') {
      dispatchPush(url)
    }
  }

  return (
    <Week year={year} week={week} registerWatch={gotoRegister} editWatch={gotoEdit} />
  )
}

const mapDispatchToProps = {dispatchPush: push}

export default connect(null, mapDispatchToProps)(Calendar)
