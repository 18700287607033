import React from 'react';
import Link from './Link'
import './Ident.css'

export default function () {
  return (
    <div className="Ident">
      <Link to="/" className="Ident-link">
        <span className="Ident-highlight"><span className="Ident-higherlight">Ber</span>gen</span><span className="Ident-space"> </span>ber
      </Link>
    </div>
  )
}
